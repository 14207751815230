import { gql } from 'apollo-boost';

export enum Screens {
  GetAllScreens = 'getallScreens',
  GetScreensById = 'getscreensbyid',
  GetscreensStatusByCompanyId = 'getscreensstatusbycompanyid',
  AddScreen = 'addScreen',
  UpdateScreen = 'updateScreen',
  DeleteScreen = 'deleteScreen',
  GetScreensByCompanyId = 'getscreensbycompanyid',
  SearchScreen = 'searchscreenbyname',
  ChangeVolume = 'changeVolume',
  ChangeBrightness = 'changeBrightness',
  DevicePowerAction = 'devicePowerAction',
  UpdateScreenList = 'updateScreenList',
}

export const getScreensStatusByCompanyIdQuery = gql`
  query ${Screens.GetscreensStatusByCompanyId}($companyId: String!){
    ${Screens.GetscreensStatusByCompanyId}(id: $companyId) {
      statusCode
      message
      data {
        id
        lastPing
      }
    }
  } 
`;

export const getAllScreensQuery = gql`
  query ${Screens.GetAllScreens}{
    ${Screens.GetAllScreens} {
      statusCode
      message
      data {
        hashCode
        name
        id
        created_at
        company {
          email
          address
          city
          name
          postal_code
          org_no
          phone_number
          country_code
          id
          created_at
        }
      }
    }
  } 
`;

// query{
//   getscreensstatusbycompanyid(id:"cda92f4f-749c-4ac9-ae79-93a44e4e9df2"){
//     statusCode
//       data{
//       name
//         id
//         hashCode
//     }
//   }
// }

export const getScreensByCompanyIdQuery = gql`
  query ${Screens.GetScreensByCompanyId}($companyId: String!, $folderId: String $companyUserId: String){
    ${Screens.GetScreensByCompanyId}(company: $companyId, folderId: $folderId companyUserId: $companyUserId) {
      statusCode
      message
      data {
        onlineCount
        offlineCount
        screenz {
          hashCode
          name
          id
          created_at
          screenShotUrl
          screenShotTiming
          lastPing
          modelName
          sortOrder
          isOnline
          owner{
            id
          }
          sharedAt
          currentContent {
            id
            name
            contentType
          }
          currentPlayList {
            id
            companyId
            name
          }
          company {
            email
            address
            city
            name
            postal_code
            org_no
            phone_number
            country_code
            id
            created_at
          }
      }
      }
    }
  } 
`;

export const getScreenByIdQuery = gql`
  query ${Screens.GetScreensById}(
    $id: String!
  ){
    ${Screens.GetScreensById}(
      id: $id
    ){
      statusCode
      message
      name
      data {
        hashCode
        name
        id
        created_at
        lastPing
        brightness
        volume
        screenShotUrl
        isOnline
        screenShotTiming
        company {
          email
          address
          city
          name
          postal_code
          org_no
          phone_number
          country_code
          id
          created_at
          updated_at
        }
        currentContent {
          id
          folder {
            id
          }
          name
          url
          contentType
          size
          dimension
          duration
          sortOrder
          thumbnailPath
          updated_at
          company {
            email
            address
            city
            name
            postal_code
            org_no
            phone_number
            country_code
            id
            created_at
            updated_at
          }
          user {
            id
            first_name
            last_name
            email
          }
        }
        currentPlayList {
          id
          companyId
          name
          duration
          dimention
          screenType
          status
          folderId{
            id
          }
          playListContents {
            id
            name
            url
            contentType
            size
            dimension
            folder{
              id
            }
            duration
            sortOrder
            thumbnailPath
            updated_at
          }
        }
      }
    }
  }
`;
export const updateScreenMutation = gql`
  mutation ${Screens.UpdateScreen}(
    $id: String
    $name: String!
    $companyId: String!
    $currentContentId: String
    $currentPlayListId: String
    $isUpdatingContents: Boolean
  ){
    ${Screens.UpdateScreen}(
      id: $id
      name: $name
      companyId: $companyId
      currentContentId: $currentContentId
      currentPlayListId: $currentPlayListId
      isUpdatingContents: $isUpdatingContents
    ){
      statusCode
      message
      name
      data {
        id
      }
    }
  }
`;

export const addScreenMutation = gql`
  mutation ${Screens.AddScreen}(
    $hash: String!
    $companyId: String!
    $name: String!
    $folderId: String
    $companyUserId: String
    $userId: String!
  ){
    ${Screens.AddScreen}(
      hash: $hash
      companyId: $companyId
      name: $name
      folderId: $folderId
      companyUserId: $companyUserId
      userId: $userId
    ){
      statusCode
      message
      name
      data {
        hashCode
        name
        id
        created_at  
        company {
          email
          address
          city
          name
          postal_code
          org_no
          phone_number
          country_code
          id
          created_at
        }
      }
    }
  }
`;

export const deleteScreenByIdMutation = gql`
  mutation ${Screens.DeleteScreen}(
    $id: String!
  ){
    ${Screens.DeleteScreen}(
      id: $id
    ){
      statusCode
      message
      name
      data {
        deleted
      }
    }
  }
`;

export const searchScreenMutation = gql`
  mutation ${Screens.SearchScreen}(
    $name: String
    $company: String!
    $folder: String
  ){
    ${Screens.SearchScreen}(
      name: $name
      company: $company
      folder: $folder
    ){
      statusCode
      message
      name
      data {
        hashCode
        name
        id
        isOnline
        created_at  
        screenShotUrl
        owner{
          id
        }
        sharedAt
        company {
          email
          address
          city
          name
          postal_code
          org_no
          phone_number
          country_code
          id
          created_at
        }
      }
    }
  }
`;

export const changeVolumeMutation = gql`
  mutation ${Screens.ChangeVolume} ($screenId: String, $volume: Int!) {
    ${Screens.ChangeVolume}(screenId: $screenId, volume: $volume) {
      statusCode
      message
      data {
        message
      }
    }
  }
`;

export const changeBrightnessMutation = gql`
  mutation ${Screens.ChangeBrightness} ($screenId: String, $brightness: Int!) {
    ${Screens.ChangeBrightness}(screenId: $screenId, brightness: $brightness) {
      statusCode
      message
      data {
        message
      }
    }
  }
`;

export const devicePowerActionMutation = gql`
  mutation ${Screens.DevicePowerAction} (
    $screenId: String
    $devicePowerAction: DevicePowerAction!
    ) {
    ${Screens.DevicePowerAction}(
      screenId: $screenId
      devicePowerAction: $devicePowerAction
      ) {
      statusCode
      message
      data {
        message
      }
    }
  }
`;

export const updateScreenListMutation = gql`
  mutation ${Screens.UpdateScreenList} (
    $screens: [Screen]
    ) {
    ${Screens.UpdateScreenList}(
      screens: $screens
      ) {
      statusCode
      message
      data {
        updated
      }
    }
  }
`;
